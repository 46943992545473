import React from 'react';
import '../../App.css';
import EntrySection from '../EntrySection';
import Footer from '../Footer';


function Homepage() {
  return (
    <>
      <EntrySection />
      <Footer />
    </>
  );
}

export default Homepage;
